<template>
  <svg viewBox="0 0 140 140">
    <g stroke="currentcolor" stroke-width="1">
      <path
        d="M126.78,33.63a3.31,3.31,0,0,0-.93-.13,3.44,3.44,0,0,0-1.65.43l-4.83,2.7a3.41,3.41,0,0,0-1.32,4.63,3.47,3.47,0,0,0,4.63,1.31l4.83-2.69a3.4,3.4,0,0,0-.73-6.25Z"
      />
      <path
        d="M68.57,27.67A41.45,41.45,0,0,0,31.78,87.41a3.45,3.45,0,0,0,4.57,1.5h0a3.4,3.4,0,0,0,1.5-4.57A34.33,34.33,0,1,1,102.8,68.71,33.63,33.63,0,0,1,99,84.05a3.4,3.4,0,0,0,4.09,4.78,3.33,3.33,0,0,0,2-1.7,40.35,40.35,0,0,0,4.52-18.42A41.09,41.09,0,0,0,68.57,27.67Z"
      />
      <path
        d="M128.62,65.31a3.4,3.4,0,1,0,0,6.8h5.54a3.4,3.4,0,1,0,0-6.8Z"
      />
      <path
        d="M3.54,72.11H9.08a3.4,3.4,0,1,0,0-6.8H3.54a3.4,3.4,0,0,0,0,6.8Z"
      />
      <path
        d="M134,102.11h0a3.37,3.37,0,0,0-2.4,1,7.12,7.12,0,0,1-9.77,0,13.18,13.18,0,0,0-19.2,0,7.13,7.13,0,0,1-4.83,2,7.66,7.66,0,0,1-5-2,13.24,13.24,0,0,0-19.19,0,7,7,0,0,1-9.86,0,13.82,13.82,0,0,0-19.2,0,7.29,7.29,0,0,1-5,2,7.13,7.13,0,0,1-4.81-2,13.79,13.79,0,0,0-19.25,0,6.88,6.88,0,0,1-9.62,0,3.41,3.41,0,0,0-2.41-1h0a3.46,3.46,0,0,0-2.41,1,3.42,3.42,0,0,0,0,4.82,13.81,13.81,0,0,0,19.25,0,6.86,6.86,0,0,1,9.62,0,13.83,13.83,0,0,0,9.56,4,14.37,14.37,0,0,0,9.91-4,6.81,6.81,0,0,1,9.59,0,13.77,13.77,0,0,0,19.47,0,7.14,7.14,0,0,1,4.8-2.33A7.12,7.12,0,0,1,88,107.93a14,14,0,0,0,9.76,4v2.13l.12-2.14a13.92,13.92,0,0,0,9.6-4c2.9-2.91,6.61-3,9.58,0a14.14,14.14,0,0,0,19.38,0,3.41,3.41,0,0,0-2.4-5.82Z"
      />
      <path
        d="M39.14,10.45a3.45,3.45,0,0,0-3-1.7,3.32,3.32,0,0,0-1.7.46,3.36,3.36,0,0,0-1.59,2.06,3.41,3.41,0,0,0,.34,2.58L36,18.65a3.48,3.48,0,0,0,4.65,1.24,3.41,3.41,0,0,0,1.25-4.64Z"
      />
      <path
        d="M18.4,36.41,13.58,33.7a3.42,3.42,0,0,0-1.67-.44,3.25,3.25,0,0,0-.92.13,3.4,3.4,0,0,0-.75,6.24l4.82,2.72a3.47,3.47,0,0,0,4.63-1.3h0A3.39,3.39,0,0,0,20,38.46,3.44,3.44,0,0,0,18.4,36.41Z"
      />
      <path
        d="M107.46,133.39c2.91-2.91,6.61-3,9.58,0a14.14,14.14,0,0,0,19.38,0,3.41,3.41,0,0,0-2.4-5.82h0a3.37,3.37,0,0,0-2.4,1,7.12,7.12,0,0,1-9.77,0,13.71,13.71,0,0,0-9.56-4.32h0a14,14,0,0,0-9.63,4.32,7.18,7.18,0,0,1-4.83,2.05h0a7.24,7.24,0,0,1-5-2.05,13.25,13.25,0,0,0-19.19,0,7,7,0,0,1-9.86,0,13.79,13.79,0,0,0-9.6-4,13.5,13.5,0,0,0-9.6,4,7,7,0,0,1-9.86,0,13.79,13.79,0,0,0-19.25,0,6.88,6.88,0,0,1-9.62,0,3.41,3.41,0,0,0-2.41-1,3.46,3.46,0,0,0-2.41,1,3.42,3.42,0,0,0,0,4.82,13.81,13.81,0,0,0,19.25,0,6.86,6.86,0,0,1,9.62,0,13.9,13.9,0,0,0,9.56,4,13.73,13.73,0,0,0,9.91-4,6.81,6.81,0,0,1,9.59,0,13.77,13.77,0,0,0,19.47,0c2.94-2.95,6.62-3,9.58,0a14,14,0,0,0,9.75,4h0l.18,2.13,0-2.13A13.9,13.9,0,0,0,107.46,133.39Z"
      />
      <path
        d="M64.62,3.4V8.94a3.4,3.4,0,0,0,6.8,0V3.4a3.4,3.4,0,0,0-6.8,0Z"
      />
      <path
        d="M96.69,18.59a3.42,3.42,0,0,0,1.53,2.11,3.47,3.47,0,0,0,4.68-1.13l2.89-4.72a3.41,3.41,0,0,0-1.13-4.68,3.34,3.34,0,0,0-1.76-.5A3.4,3.4,0,0,0,100,11.3L97.09,16A3.36,3.36,0,0,0,96.69,18.59Z"
      />
    </g>
  </svg>
</template>
